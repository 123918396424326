import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Imagelocalizacao from '../Img/loc.png';
import Imagetelefone from '../Img/tel.png';
import Imagedelivery from '../Img/deliv.png';
import Imageentrega from '../Img/entrega.png';
import Imagenpesquisa from '../Img/search.png';
import Menu from './Menu.jsx';
import axios from 'axios';
import Popup from "reactjs-popup";
import Filtrobuttonimg from '../Img/buscafiltro.png';

const Formulario = styled.form`
display: block;
width: 100%;
height: auto;
`

const Boxcampo = styled.label`
display: block;
width: 100%;
font-style: normal;
font-weight: 900;
font-size: 28px;
line-height: 42px;
color:#575757;
`
const TextoBairro = styled.span`
display: block;
width: 100%;
font-style: normal;
font-weight: 900;
font-size: 32px;
line-height: 48px;
color: #9BA0D8; 
margin-bottom: 20px;
`
const Texto = styled.span`
display: block;
font-size: 20px;
font-weight: 800;
margin-top: 20px;
margin-bottom: 20px;
position: relative;
`

const Campo = styled.input`
display: block;
width: 95%;
height: 40px;
font-size: 22px;
background-color: #ACB0DA;
box-shadow: 0px 3px 2px #ACB0DA;
border-radius: 10px;
position: relative;
background-image: url(${Imagenpesquisa});
background-repeat: no-repeat;
background-position: 98% 50%;
float: left;
`

const Filtrobutton = styled.div`
width: 5%;
height: 40px;
display: block;
border: 1px solid #ACB0DA;
box-sizing: border-box;
box-shadow: 0px 3px 2px #ACB0DA;
border-radius: 10px;
background-image: url(${Filtrobuttonimg});
background-repeat: no-repeat;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
float: left;
@media( max-width: 425px){
    width: 15%;
}
`

const Ordem = styled.input`
width: 140px;
height: 46px;
display: block;
border: 1px solid #ACB0DA;
box-sizing: border-box;
box-shadow: 0px 3px 2px #ACB0DA;
border-radius: 10px;
background-color: #FFFBF0;
margin: 20px 10px;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
`
const Containercard = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
@media(max-width: 425px){
    width: 100%;
}
`
const Containeordem = styled.div`
display: flex;
flex-direction: row;
@media(max-width: 425px){
    display: block;
}

`
const Card = styled.div`
width: 350px;
height: 275px;
display: block;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 10px;
margin-bottom: 20px;
cursor: pointer;
`

const Titlecard = styled.h4`
width: 100%;
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 30px;
background-color: #4A5CA7;
color: #fff;
padding: 10px;
border: 1px solid transparent;
`

const Statuscard = styled.span`
width: 100%;
margin-left: 10px;
margin-top: 10px;
color: #008AC8;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 29px;
`

const Localizacao = styled.span`
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
display: block;
margin-left: 5px;
position: relative;
margin-left: 50px;
margin-right: 10px;
margin-top: 10px;
&::after{
    content: ' ';
    width: 15px;
    height: 20px;
    background: url(${Imagelocalizacao});
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 5px;
    left: -35px;
}
`

const Telefone = styled.span`
display: block;
color:#934B97;
font-family: 'Martel Sans';
position: relative;
margin-left: 50px;
margin-top: 10px;
font-style: normal;
font-weight: bold;
font-size: 16px;
&::after{
    content: ' ';
    width: 15px;
    height: 20px;
    background: url(${Imagetelefone});
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 0px;
    left: -35px;
}
`

const Tag = styled.span`
font-family: 'Martel Sans';
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 24px;
display: block;
color: #934B97;
margin-left: 10px;
margin-right: 20px;
margin-top: 20px;
float: left;
`

const Delivery = styled.span`
font-family: Martel Sans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 29px;
display: block;
width: 100%;
position: relative;
margin-left: 50px;
margin-top: 30px;
color: #575757;
&::after{
    content: ' ';
    width: 15px;
    height: 20px;
    background: url(${Imagedelivery});
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 5px;
    left: -35px;
}
`

const Entrega = styled.span`
font-family: Martel Sans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 29px;
display: block;
width: 100%;
position: relative;
margin-left: 50px;
margin-top: 5px;
margin-bottom: 20px;
color: #575757;
&::after{
    content: ' ';
    width: 15px;
    height: 20px;
    background: url(${Imageentrega});
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 5px;
    left: -35px;
}
`
const Listhour = styled.ul`
list-style: none;
padding: 0;
margin: 0 10px;
background-color: #E5E5E5;
padding-top: 20px;
padding-bottom: 20px;
margin-bottom: 20px;
`

const Dayhour = styled.li`
font-family: Martel Sans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 29px;
color: #575757;
margin-left: 10px;
margin-top: 10px;
`

const Sobre = styled.span`
font-style: normal;
font-weight: 900;
font-size: 20px;
line-height: 30px;
color: #7C7B7B;
margin-left: 20px;
display: block;
margin-top: 100px;
`
const Sobretexto = styled.p`
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 26px;
margin-top: 10px;
margin-bottom: 40px;
margin-left: 20px;
margin-right: 20px;
`

const Redesociais = styled.span`
position: relative;
display: block;
height: 40px;
margin-left: 15px;
margin-top: 15px;
margin-bottom: 15px;
`

class Busca extends Component {
    constructor() {
        super()
        this.state = {
            categories: [],
            stateall: [],
            merchantall: [],
            indexstate: null,
            indexcity: null,
            indexbairro: null,
            wordSearch: '',
            s_state: '',
            s_city: '',
            s_neighborhood: '',
            s_category: '',
            ss_category: '',
            ss_state: '',
            ss_city: '',
            ss_neighborhood: '',
            headerRequest: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            s_limit: 6,
            s_offset: 0,
        }
    }

    BuscaMerchant = () => {
        let url = 'https://apicompredopequeno.opovo.com.br/api/v1/merchant';

        if (this.state.wordSearch) url = 'https://apicompredopequeno.opovo.com.br/api/v1/search';
        
        if (this.state.s_state) {
            url += '/' + this.state.s_state 

            if (this.state.wordSearch) {
                url += '/' + this.state.wordSearch + '/' + this.state.s_offset
                this.setState({ s_offset: this.state.s_offset + 6 });
            }

            if (this.state.s_city) {
                url += '/' + this.state.s_city 
                if(this.state.s_neighborhood){
                    url += '/' + this.state.s_neighborhood
                } 
            }

            if (this.state.categories) url += '/' + this.state.s_category
            
            if (!this.state.wordSearch) {
                url += '?limit=' + this.state.s_limit;
                url += '&offset=' + this.state.s_offset;
                this.setState({ s_offset: this.state.s_offset + 6 });
            }
        }
        axios.get(url, {headers: this.state.headersRequest})
        .then(res => {
            this.setState({ merchantall: [...this.state.merchantall, ...res.data] });
        })
        .catch(error => {
            if (error.response) {
                // console.log(error.response)   
                if (error.response.data.message) this.setState({ merchantall: [] })
            } else if (error.request) {
                // console.log(error.request);
            } else {
                /* console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message); */
            }
        })
    }

    WordSearchChange = e => this.setState({wordSearch: e.target.value})

    StateStoreChange = (e, resolve = true) => { 
        let callback 
        if (resolve) callback = this.BuscaMerchant        
    
        this.setState({
            s_state: this.state.stateall[e.target.value].uf,
            ss_state: this.state.stateall[e.target.value].name,
            indexstate: e.target.value,
        }, callback)
    }

    CityStoreChange = (e, resolve = true) => {
        let callback 
        if (resolve) callback = this.BuscaMerchant    

        this.setState({ 
            s_city: this.state.stateall[this.state.indexstate].cities[e.target.value].code,
            ss_city: this.state.stateall[this.state.indexstate].cities[e.target.value].name,
            indexcity: e.target.value 
        }, callback)
    }

    NeighborhoodStoreChange = (e, resolve = true) => {
        let callback 
        if (resolve) callback = this.BuscaMerchant    

        this.setState({
            s_neighborhood: e.target.value,
            ss_neighborhood: e.target.label,
        }, callback);
    } 

    CategoriesvalueStoreChange = (e, resolve = true) => {
        let callback 
        if (resolve) callback = this.BuscaMerchant    

        this.setState({ 
            s_category: e.target.value,
            ss_category: this.state.categories[e.target.value].name
        }, callback);
    }

    async componentWillMount() {
        await this.getInformation()
        await this.getCategorios()
        this.Getparameter()
    }

    getInformation(){
        return new Promise(resolve => {            
            axios.get('https://apicompredopequeno.opovo.com.br/api/v1/information', {
                headers: this.state.headersRequest,
            })
            .then(res => this.setState({ stateall: res.data }, resolve))            
            .catch(error => {
                if (error.response) {
                    // console.log(error.response)   
                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    /* console.log('Error', error);
                    console.log('Error', error.response);
                    console.log('Error', error.message); */
                }
            })
        })
    }

    getCategorios(){
        return new Promise(resolve => {            
            axios.get(`https://apicompredopequeno.opovo.com.br/api/v1/categories`, {
                headers: this.state.headersRequest,
            })
            .then(res => this.setState({ categories: res.data }, resolve))            
            .catch(error => {
                if (error.response) {
                    // console.log(error.response)   
                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    /* console.log('Error', error);
                    console.log('Error', error.response);
                    console.log('Error', error.message); */
                }
            })
        })   
    }

    createFakeEvent = value => { 
        return { target: { value: value } } 
    }

    async Getparameter() {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let index_state = url.searchParams.get('e');
        let index_city = url.searchParams.get('c');
        let category_id = url.searchParams.get('id');
        let word = url.searchParams.get('b');
        let executeBusca = false;

        if(index_state){
            await this.StateStoreChange(this.createFakeEvent(index_state), executeBusca);
        }
        if (category_id) {
            await this.CategoriesvalueStoreChange(this.createFakeEvent(category_id), executeBusca);
            
            if (index_city) {
                await this.CityStoreChange(this.createFakeEvent(index_city), executeBusca);
            }

            this.BuscaMerchant();
            return
        } 

        await this.WordSearchChange(this.createFakeEvent(word));        
        this.BuscaMerchant();
    }

    render() {
        return (
            <section className="busca">
                <Menu></Menu>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <Formulario>
                                <Boxcampo>Mostrando o Resultados para:
                                    <Campo type="text" name="search" className="campo campomobile" ></Campo>
                                    <Popup trigger={
                                        <Filtrobutton className="poppesquisa"></Filtrobutton>
                                    }
                                    position="top center"
                                    closeOnDocumentClick
                                    modal>
                                         <Containeordem>
                                    <label className="statusselecte selectestado">Estado
                                        <select onChange={this.StateStoreChange} name="estado" className="selectestadoinput selectesbusca">
                                            <option value="nada">Selecione um Estado</option>
                                            {this.state.stateall.map((state, index) => (
                                                <option value={index}>{state.name}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="statusselecte selectcidade">Cidade
                                        <select select="cidade" onChange={this.CityStoreChange} className="selectesbusca">
                                            <option value="nada">Selecione um Cidade</option>
                                            {((this.state.indexstate ? this.state.stateall[this.state.indexstate].cities : [])).map((cidade, index) =>(
                                                <option value={index}>{cidade.name}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="statusselecte selectbairro">Bairro
                                        <select select="bairro" onChange={this.NeighborhoodStoreChange} className="selectesbusca">
                                        <option value="nada">Selecione um Bairro</option>
                                            {((this.state.indexcity && this.state.stateall[this.state.indexstate].cities[this.state.indexcity].neighborhood.length > 0 ? this.state.stateall[this.state.indexstate].cities[this.state.indexcity].neighborhood : [])).map((bairro) =>(
                                                <option value={bairro.code}>{bairro.name}</option>
                                            ))} 
                                        </select>
                                    </label>
                                    <label className="statusselecte selectcategoria">Categoria
                                        <select onChange={this.CategoriesvalueStoreChange} className="selectesbusca">
                                            <option value="nada">Selecione um categoria</option>
                                            {this.state.categories.map((todo, index) => (
                                                <option value={todo.id}>{todo.name}</option>
                                            ))}
                                        </select>
                                    </label>
                                </Containeordem>
                                    </Popup>  
                                </Boxcampo>
                                <Texto>Resultado</Texto>
                                {/* <Containeordem>                                
                                    <Ordem type="button" value="Mais Proxima" name="maisproxima"></Ordem>
                                    <Ordem type="button" value="Nome" name="nome"></Ordem>
                                      
                                </Containeordem>                                 */}
                               
                            </Formulario>
                            
                            <TextoBairro>{this.state.ss_neighborhood}</TextoBairro>
                            <Containercard>
                                {this.state.merchantall.length <= 0 ? (
                                    <h2>Nehuma loja foi encontrada</h2>
                                ) : (
                                    this.state.merchantall.map((todo, index) => (
                                        <Popup trigger={
                                            <Card>
                                                <Titlecard>{todo.store}</Titlecard>
                                                <Localizacao>{todo.street}, {todo.number}</Localizacao>
                                                <Telefone>{todo.phone}</Telefone>
                                                <Tag>{todo.category}</Tag>
                                            </Card>
                                        }
                                        position="top center"
                                        closeOnDocumentClick
                                        modal>                                
                                            <div className="bigcard">
                                                <Titlecard>{todo.store}</Titlecard>
                                                <Localizacao>{todo.street}, {todo.number}</Localizacao>
                                                <Telefone>{todo.phone}</Telefone>
                                                {/* <Listhour>
                                                    <Dayhour>seg:{todo.seg}</Dayhour>
                                                    <Dayhour>ter:{todo.ter}</Dayhour>
                                                    <Dayhour>quar:{todo.qua}</Dayhour>
                                                    <Dayhour>qui:{todo.qui}</Dayhour>
                                                    <Dayhour>sex:{todo.sex}</Dayhour>
                                                    <Dayhour>sab:{todo.sab}</Dayhour>
                                                    <Dayhour>dom:{todo.dom}</Dayhour>
                                                </Listhour> */}
                                                <a href={todo.facebook} className="redesociaisfloat"  target="_blank"><Redesociais className="facebook"></Redesociais></a>
                                                <a href={'https://www.instagram.com/'+ todo.instagram} className="redesociaisfloat" target="_blank"><Redesociais className="instagram"></Redesociais></a>
                                                {/* <Redesociais className="wpp"></Redesociais> */}
                                                <Sobre>Sobre</Sobre>
                                                <Sobretexto>{todo.about}</Sobretexto>
                                                <Tag>#{todo.category}</Tag>
                                            </div>
                                        </Popup>
                                        // <Card>
                                        //     <Titlecard>{todo.store}</Titlecard>
                                        //     <Localizacao>{todo.street}, {todo.number}</Localizacao>
                                        //     <Telefone>{todo.phone}</Telefone>
                                        //     <Tag>{todo.category}</Tag>
                                        // </Card>
                                    ))
                                )}
                            </Containercard>
                            {this.state.s_offset > 0 ? <button className="loadmore" onClick={this.BuscaMerchant}>Carregar mais</button> : ''}
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Busca