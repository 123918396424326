import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logoimg from '../../Img/opovo2.png';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


const Logonav = styled.img`
width: 200px;
height: auto;
display: block;
padding-top: 30px;
padding-bottom: 30px;
`
const Titlexpediente = styled.h1`
color: #fff;
margin-top: 50px;
margin-bottom: 40px;
font-weight: 800;
`
const Containerexpediente = styled.div`
display: block;
width: 255px;
float: left;
height: 100px;
`
const Cartolaexpediente = styled.span`
font-size: 18px;
color: #fff;
display: block;
font-weight: 800;
`

const Nomescartola = styled.span`
color: #fff;
margin-top: 10px;
`


class Expediente extends Component {
    render(){
        return(
            <section className="expediente">
                <nav class="navbar navbar-light  lighten-4 expediente-side">
                    <Container>
                           <Logonav className='logo-ex' src={Logoimg}></Logonav>
                            <Nomescartola className ='termo'><Link to='/termodeuso'>Termos de uso</Link></Nomescartola>
                            <button class="navbar-toggler toggler-example float-right exp" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                                aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><Nomescartola>Expediente</Nomescartola></button>
                    </Container>

                            <div class="collapse navbar-collapse color-side" id="navbarSupportedContent1">
                                <Container>
                                <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                   <Titlexpediente>Projeto</Titlexpediente>
                                   <Containerexpediente>
                                    <Cartolaexpediente>Concepção e direção geral</Cartolaexpediente>
                                    <Nomescartola>Jimmy Lucas</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Diretor Geral de comunicação e Negócios</Cartolaexpediente>
                                    <Nomescartola>Marcus Soares</Nomescartola>
                                    </Containerexpediente>
                                </li>
                                <li>
                                    <Titlexpediente>PRODUÇÃO</Titlexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Gerência de Produção</Cartolaexpediente>
                                    <Nomescartola>Gilvana Marques</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Produção</Cartolaexpediente>
                                    <Nomescartola>Ana Luisa Gomes</Nomescartola>
                                    </Containerexpediente>
                                </li>
                                <li>
                                    <Titlexpediente>MARKETING</Titlexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Gerente de Marketing</Cartolaexpediente>
                                    <Nomescartola>Natércia Melo</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Projeto Gráfico</Cartolaexpediente>
                                    <Nomescartola>Gil Dicelli e Jansen Lucas</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Edição de Arte e design</Cartolaexpediente>
                                    <Nomescartola>Jansen Lucas</Nomescartola>
                                    </Containerexpediente>
                                </li>
                                <li>
                                    <Titlexpediente>TECNOLOGIA</Titlexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Head de Estratégia Digital</Cartolaexpediente>
                                    <Nomescartola>André Filipe Dummar</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Gerente de TI</Cartolaexpediente>
                                    <Nomescartola>Maria Fernandes</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Gerente de Inovação / UX</Cartolaexpediente>
                                    <Nomescartola>Brenda Câmara</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Web: designer</Cartolaexpediente>
                                    <Nomescartola>David Jucimon</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Front-End</Cartolaexpediente>
                                    <Nomescartola>Pedro e Geisa</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Back- End</Cartolaexpediente>
                                    <Nomescartola>Hudson Sampaio e Milton Nascimento</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Infra-Estrutura</Cartolaexpediente>
                                    <Nomescartola>David Santos</Nomescartola>
                                    </Containerexpediente>
                                </li>
                                <li>
                                    <Titlexpediente>LABETA</Titlexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Gerente Geral do LABETA</Cartolaexpediente>
                                    <Nomescartola>Gil Dicelli</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Editores-Executivos</Cartolaexpediente>
                                    <Nomescartola>Paula Lima e Raphael Góes</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Edição</Cartolaexpediente>
                                    <Nomescartola>Amanda Araújo e Paula Lima</Nomescartola>
                                    </Containerexpediente>
                                    <Containerexpediente>
                                    <Cartolaexpediente>Textos</Cartolaexpediente>
                                    <Nomescartola>Ana Beatriz Caldas</Nomescartola>
                                    </Containerexpediente>
                                </li>
                                </ul>
                                </Container>
                            </div>
                </nav>
            </section>
        )
    }
}


export default Expediente;