import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Esqueciasenha from './Login/Esqueciasenha.jsx'
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";
import Menu from './Menu.jsx';
import axios from 'axios';
import Houseimagen from '../Img/house.png'
const Formulario = styled.form`
display: block;
width: 50%;
float: left;
height: 400px;
border: 1px solid #C4C4C4;
border-radius: 10px;
margin: 60px auto;
padding-bottom: 100px;
@media(max-width: 425px){
    width: 100%;
}
`

const Boxslide = styled.div`
background-color: #9BA0D8;
height: 400px;
width: 45%;
float: left;
margin: 60px auto;
margin-right: 20px;
@media(max-width: 425px){
    display: none;
}
`
const Imgicon = styled.div`
margin: 125px auto;
display: block;
`
const Boxcampo = styled.label`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 29px;
margin: 0 15px;
color: #575757;
`
const Campo = styled.input`
display: block;
background-color:#FFFBF0;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
width: 100%;
padding-left: 10px;
`
const Cadastrar = styled.button`
width:99px;
height: 50px;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 33px;
float: right;
color: #575757;
margin-top: 15px;
margin-right: 15px;
background-color: #FFFBF0;
border: 1px solid #ACB0DA;
box-sizing: border-box;
box-shadow: 0px 3px 2px #ACB0DA;
border-radius: 10px;
position: relative;
&:hover{
    background-color: #4A5CA7;
    color: #fff;
}
`
const Titlebox = styled.h1`
display: block;
font-family: Rounded Mplus 1c;
font-style: normal;
font-weight: 900;
font-size: 28px;
line-height: 42px;
color: #575757;
margin-left: 15px;
`

class Login extends Component {
    constructor() {
        super()
        this.state = {
            erros: [],
            email: '',
            passsword: ''
        }
    }
    mountObjectUser() {
        return {
            "email": this.state.email,
            "password": this.state.password,
        }
    }

    sendLogin = () => {
        const credentials = this.mountObjectUser()
        
        axios.post('https://apicompredopequeno.opovo.com.br/api/v1/login', credentials, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            localStorage.setItem("token", res.data.token);
            window.location.href = `/`;
        }).catch(error => {
            if (error.response) {
                let errs = error.response.data.errors
                this.setState({erros: []})
                for(let e in errs) 
                    for(let error of errs[e]) this.setState({erros: [...this.state.erros, error] })

                console.log(error.response)   
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message);
            }
        })
    }

    emailvalueStoreChange = (e) => this.setState({ email: e.target.value });
    senhavalueStoreChange = (e) => this.setState({ password: e.target.value });
    render() {
        const erromark = {
            color: "#d93025"
        };
        return (
            <section className="login">
                <Menu></Menu>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <Boxslide>
                                <img className="imagembox" src={Houseimagen}></img>
                            </Boxslide>
                            <Formulario>
                                <div className="container-erros">
                                    <ul>
                                        {this.state.erros.map((todoserros) => (
                                            <li style={erromark}>{todoserros}</li>
                                        ))}
                                    </ul>
                                 </div>
                                 <Titlebox>Acessar</Titlebox>
                                <Boxcampo>E-mail:
                                     <Campo type="email" name="email" onChange={this.emailvalueStoreChange} placeholder="Seu Email"></Campo>
                                </Boxcampo>
                                <Boxcampo>Senha:
                                    <Campo type="password" name="senha" onChange={this.senhavalueStoreChange} placeholder="Digite sua Senha"></Campo>
                                </Boxcampo>
                                <Cadastrar type="button" onClick={this.sendLogin}>Prosseguir</Cadastrar>
                            </Formulario>
                            <Router className='itens'>
                                <Link to='/esquecisenha'>Esqueci minha senha</Link>
                                <Route path="/esquecisenha" component={Esqueciasenha}></Route>
                            </Router>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Login;