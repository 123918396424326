import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Categoria from './Home/Ofertas.jsx';
import Cadastrese from './Home/Cadastro.jsx';
import Blogs from './Home/Blogs.jsx';
import Contato from './Home/Contato.jsx';
import Bghome from '../Img/bgnew.png';
import Bghomemobile from '../Img/bgnewmobile.png';
import Logotopo from '../Img/logo.png';
import Apoio from '../Components/Home/Apoio.jsx';
import Menu from '../Components/Menu.jsx';
import Expediente from '../Components/Home/Expediente.jsx';
import {Bling as GPT} from "react-gpt";
import { DFPSlotsProvider, AdSlot } from 'react-dfp';


const Title = styled.h1`
display: block;
text-align: left;
color: #fff;
font-style: normal;
font-weight: 900;
font-size: 36px;
line-height: 53px;
`
const Titledestaque = styled.h1`
display: block;
text-align: center;
color: #fff;
font-style: normal;
font-weight: 900;
font-size: 60px;
line-height: 89px;
padding-top: 200px;
@media (max-width: 425px){
    padding-top: 75px;
}
` 
const Text = styled.p`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 40px;
text-align: left;
color: #fff;
`
const Bgdegrade = styled.div`
width: 100%;
height: 650px;
background: transparent;
margin-bottom: 0px;
position: relative;
@media(max-width: 425px){
    height: 300px;
    margin-bottom: 50px;
}
@media(max-width: 375px){
    height: 300px;
    margin-bottom: 30px;
}
@media(max-width: 320px){
    height: 300px;
    margin-bottom: 30px;
}
`
const Bgimage = styled.div`
width:  100%;
z-index: -1;
height: 650px;
position absolute;
background-image: url(${Bghome});
background-repeat: no-repeat;
background-size: 100%;
@media (max-width: 425px){
    background-image: url(${Bghomemobile});
    background-size: 100% 100%;
    height: 300px;
}
`
const Continaerabout = styled.div`
width: 100%;
height: 650px;
background-color:#9BA0D8;
border: 1px solid transparent;
border-radius: 10px;
padding: 150px 15px;
@media
`

const Logo = styled.img`
text-align: center;
margin: 0 auto;
display: block;
margin-top: 30px;
`
const Logotext = styled.span`
width: 210px;
height: 50px;
background-color: #555C9E;
font-size: 22px;
line-height: 33px;
color: #FFFBF0;
display: block;
text-align: center;
margin: 0 auto;
margin-top: 30px;
padding-top: 5px;
border: 1px solid transparent;
border-radius: 20px;
`
const Bannersb = styled.div`
width: 728px;
height: 90px;
margin: 30px auto;
@media( max-width: 425px){
    display: none;
}
`

const Bannertop = styled.div`
width: 300px;
height: 50px;
margin: 30px auto;
display: none;
@media( max-width: 425px){
    display: block;
}
`
// GPT.enableSingleRequest();

class Home extends Component {
    render() {
        const aboutstyle = {
            marginBottom: "50px"
        };
        return (
            <div>
                <Menu></Menu>
                <section className="about" style={aboutstyle}>
                <Bannersb>  
                                {/* <GPT className="bannersb"
                                    id="div-gpt-ad-1588254712475-0"
                                    adUnitPath="/10216148/compredopequeno_sb"
                                    slotSize={[728, 90]}
                                />   */}
                        <DFPSlotsProvider>
                            <AdSlot slotId="div-gpt-ad-1588254712475-0" adUnit="10216148/compredopequeno_sb" sizes={[[728, 90]]} />    
                        </DFPSlotsProvider> 
                </Bannersb>
                <Bannertop>
                        <DFPSlotsProvider>
                            <AdSlot slotId="div-gpt-ad-1591291255181-0" adUnit="10216148/compredopequeno_tb" sizes={[[300, 50]]} />    
                        </DFPSlotsProvider> 
                </Bannertop>
                
                    <Container>
                        <Row>
                        <Col lg="6" md="6" sm="6" classname="">
                                <Bgdegrade>
                                    <Bgimage></Bgimage>
                                </Bgdegrade>
                            </Col>
                            <Col lg="6" md="16" sm="6"> 
                                <Continaerabout>
                                <Title>Sobre</Title>
                                <Text>O projeto Compre do Pequeno do O POVO é uma parceria com o Sebrae-CE. Nesse cenário de profunda crise, o Compre do Pequeno vai impulsionar o movimento solidário que incentiva que todo mundo faça suas compras de pequenos empreendedores</Text>
                                </Continaerabout>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Categoria></Categoria>
                <Cadastrese></Cadastrese>
                <Blogs></Blogs>
                <Apoio></Apoio>
                <Contato></Contato>
                <Expediente></Expediente>
            </div>
        )
    }
}
export default Home