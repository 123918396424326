import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import axios from 'axios';

const Formulario = styled.form`
display: block;
width: 600px;
height: 300px;
border: 1px solid transparent;
border-radius: 10px;
background-color: #fff;
margin: 60px auto;
`
const Boxcampo = styled.label`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
margin: 0 15px;
`
const Campo = styled.input`
display: block;
background-color:#FFFBF0;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
width: 100%;
padding-left: 10px;

`
const Cadastrar = styled.button`
width:99px;
height: 50px;
background-color: #FFFBF0;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 10px;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 33px;
float: right;
margin-top: 15px;
margin-right: 15px;
&:hover{
    background-color: #c4c4c4;
    color: #fff;
}
`

class Esqueciasenha extends Component{
    constructor() {
        super()
        this.state = {
            email: '',
            messagestatus: [],
        }
    }
    mountObjectUser() {
        return {
            "email": this.state.email,
        }
    }
    resendPassword = () => {
        const credentials = this.mountObjectUser()

        axios.post('https://apicompredopequeno.opovo.com.br/api/v1/reset_password', credentials, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            document.getElementById('formReset').reset();
            this.setState({messagestatus: [res.data.message]});
        }).catch(error => {
            if (error.response) {
                let errs = error.response.data.errors
                this.setState({erros: []})
                for(let e in errs) 
                    for(let error of errs[e]) this.setState({erros: [...this.state.erros, error] })

                console.log(error.response)   
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message);
            }
        })
    }

    emailvalueStoreChange = (e) => this.setState({ email: e.target.value });
    render(){
        return(
            <section className="esqueciasenha">
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                        <Formulario id="formReset">                
                                <div className="container-erros">
                                    <ul>
                                         {this.state.messagestatus.map((messager, key) => (
                                            <li>{messager}</li>
                                        ))}
                                    </ul>
                                 </div>
                                <Boxcampo>Email:
                                     <Campo type="email" name="email" onChange={this.emailvalueStoreChange}></Campo>
                                </Boxcampo>
                                <Cadastrar type="button" onClick={this.resendPassword} >Enviar</Cadastrar>
                            </Formulario>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Esqueciasenha