import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logoimg from '../Img/logo.png';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    IndexRoute,
    Link
} from "react-router-dom";
import Home from './Home.jsx'
import Busca from './Busca.jsx'
import CadastroComerciante from './CadastroComerciante.jsx'
import Login from './Login.jsx'
import Perfil from './Perfil.jsx'
import './Menu.css';

const Logonav = styled.img`
width: 100px;
height: 35px;
display: block;
`

const Containernav = styled.ul`
list-style: none;
padding: 0;
margin: 0;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-around;
align-item: center;
@media(max-width: 425px){
    display: none;
}
`

const Itemmenu = styled.li`
font-style: normal;
font-weight: 900;
font-size: 16px;
line-height: 24px;
color: #fff;
text-decoration: none;
`



class Menu extends Component {
    render() {
        return (
                <section className="menu">

                    <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Containernav>
                                    <Itemmenu><Link to='/'><Logonav src={Logoimg}></Logonav></Link></Itemmenu>
                                    <Itemmenu><Link to='/'>Home</Link></Itemmenu>
                                    <Itemmenu><Link to='/busca'>Busca</Link></Itemmenu>
                                    <Itemmenu><a href="http://blogs.opovo.com.br/compredopequeno/">Blog</a></Itemmenu>
                                    <Itemmenu><Link to='/cadastrocomerciante'>Cadastre sua empresa</Link></Itemmenu>
                                    <Itemmenu><Link to='/login'>Fazer login</Link></Itemmenu>
                                    
                                </Containernav>
                                <nav class="navbar navbar-light  lighten-4 menumobile">

                                <Link to='/' class="navbar-brand" ><Logonav src={Logoimg}></Logonav></Link>

                                
                                <button class="navbar-toggler toggler-example float-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                                    aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>


                                <div class="collapse navbar-collapse" id="navbarSupportedContent1">
                                    <ul class="navbar-nav mr-auto">
                                    <li class="nav-item active">
                                        <Link to='/' class="nav-link" >Home <span class="sr-only">(current)</span></Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/termodeuso' class="nav-link" >Cadastrar-se</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/login' class="nav-link" >Login</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to='/busca' class="nav-link" >Busca</Link>
                                    </li>
                                    </ul>

                                </div>


                                </nav>

                            </Col>
                        </Row>
                    </Container>
                </section>
           
        )
    }
}

export default Menu;