import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Sebrae from '../../Img/sebrae.png';
import Opovo from '../../Img/opovo.png';

const Title = styled.h1`
display: block;
margin: 0 auto;
text-align: left;
font-family: Rounded Mplus 1c;
font-style: normal;
font-weight: 900;
font-size: 36px;
line-height: 53px;
color: #575757;
width: 100%;
margin-bottom: 50px;
`
const Containerlogo = styled.ul`
margin: 0;
padding: 0;
list-style: none;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-around;
@media(max-width: 425px){
    flex-direction: column;
}
`
const Itemlogo = styled.li`
width: 50%px;
height: auto;
display: block;
padding: 20px;
border: 1px solid #ccc;
border-radius: 10px;
@media (max-width: 425px){
    margin-top: 10px;
    width: 100%;
}
@media(max-width: 375px){
    width: 100%;
}
@media (max-width: 320px){
    width: 100%;
}
`
const Boxlogo = styled.figure`
width: 100%;
height: auto;
display: block;
`
const Logo = styled.img`
width: 100%;
height: 100%;
diplay: block;
`

class Apoio extends Component{
    render(){
        const Styleapoio = {
            marginBottom: "20px"
        };
        const Stylelogo = {
            margin: "70px auto"
        }
        return(
            <section className="patrocinio" style={Styleapoio}>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <Title>Realização</Title>
                            <Containerlogo>
                                <Itemlogo>
                                    <Boxlogo>
                                        <Logo src={Sebrae}></Logo>
                                    </Boxlogo>
                                </Itemlogo>
                                <Itemlogo>
                                    <Boxlogo>
                                        <Logo src={Opovo} style={Stylelogo}></Logo>
                                    </Boxlogo>
                                </Itemlogo>
                            </Containerlogo>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Apoio