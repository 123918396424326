import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import checkboxes from './checkboxes';
import Imagenpesquisa from '../../Img/search.png';
import './Ofertas.css'
import {
    Link
} from "react-router-dom";
import axios from 'axios';

const Title = styled.h1`
display: block;
margin: 0 auto;
text-align: left;
color: #575757;
width: 100%;
margin-bottom: 50px;
font-style: normal;
font-weight: 900;
font-size: 36px;
line-height: 53px;
`
const Text = styled.p`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
margin-top: 15px;
font-size: 22px;
text-align: left;
color: #575757;
`
const Formulario = styled.form`
width: 100%;
display: block;
`
const Estado = styled.select`
width: 82px;
height: 40px;
border: 1px solid #575757;
border-radius: 5px;
outline: none
float: left;
color: #9BA0D8;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
`
const Cidade = styled.select`
width: 185px;
height: 40px;
border: 1px solid #575757;
border-radius: 5px;
outline: none
float: left;
margin-left: 22px;
color: #9BA0D8;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
`
const Buscar = styled.input`
width: 100%;
height: 40px;
display: block;
background-color: #C7CAE7;
color: #fff;
border: 1px solid transparent;
border-radius: 10px;
outline: none;
margin-top: 20px;
padding-left: 10px;
background-image: url(${Imagenpesquisa});
background-repeat: no-repeat;
background-position: 98% 50%;
`
//Checkbox custom

const Label = styled.label`
width: 153px;
height: 125px;
background-color: #FFFBF0;
border: 1px solid #ACB0DA;
box-sizing: border-box;
box-shadow: 0px 3px 2px #ACB0DA;
border-radius: 10px;
position: relative;
z-index: 9;
float: left;
margin-top: 20px;
margin-right: 5px;
color: transparent;
@media(max-width: 425px){
    width: 48%;
}
`

const Titlecheckbox = styled.span`
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
align-items: center;
text-align: center;
margin: 0 auto;
display: block;
color: #5E5757;
position: relative;
`


const Iconcat = styled.img`
width: 20px;
height: 23px;
display: block;
position: absolute;
top: -30px;
right: 0;
left: 0;
margin: 0 auto;
`

const StyledCheckbox = styled.button`
  display: inline-block;
  width: 100%;
  height: 100%;
  outline: none;
  background: #FFFBF0;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 150ms;
  position:absolute;
  z-index: -1;
  left: 0;
  top: 0;
  &:hover{
      background-color: #4A5CA7;
  }
  &:hover ${Titlecheckbox}{
    color: #f4f4f4;
  }
  &:hover ${Iconcat}{
    filter: brightness(0) invert(1);
  }
`

const Caixaerro = styled.span`
width: 100%;
display: block;
border: 1px solid #eab384;
border-radius: 10px;
font-size: 20px;
text-align: center;
color: #eab384;
margin-top: 20px;
margin-bottom: 20px;
`

class Ofertas extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          checkedItems: new Map(),
          itensid: [],   
          stateall: [],
          city: '',   
          indexstate: null,
          showblock: false,
          searchWord: ''
        }
    }

    WordSearchChange = e => this.setState({searchWord: e.target.value});

    StateStoreChange = (e) => {    
        this.setState({
            indexstate: e.target.value,
        },this.BuscaMerchant)   
    }

    CityStoreChange = (e) => {
        this.setState({ 
            city: e.target.value,
        }, this.BuscaMerchant)
    }

    handleCreate = (e) => {
        if(this.state.indexstate  == null){
            e.preventDefault();
            this.setState({ showblock: true});
            return true
        }

        this.setState({ showblock: false});
        return false
    }

    componentWillMount() {        
        axios.get('https://apicompredopequeno.opovo.com.br/api/v1/information', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(res => this.setState({ stateall: res.data }))        
        .catch(error => {
            if (error.response) {
                console.log(error.response)   
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message);
            }
        })
    }

    sendSearch = e => {
        e.preventDefault();
        if (this.handleCreate(e)) return
        window.location.href = `/busca?e=${this.state.indexstate}&b=${this.state.searchWord}`
    }

    render(){
        return(
            <section className="ofertas">
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                        <Title>Busca</Title>
                        <Text>Escolha sua região.</Text>
                            <Estado onChange={this.StateStoreChange} name="estado">                                    
                                <option value="nada">Selecione um Estado</option>
                                {this.state.stateall.map((state, index) =>( 
                                     <option value={index}>{state.name}</option>
                                ))}
                            </Estado>
                            <Cidade onChange={this.CityStoreChange} name="cidade"> 
                                <option value="nada" >Selecione um Cidade</option>
                                {((this.state.indexstate ? this.state.stateall[this.state.indexstate].cities : [])).map((cidade, index) =>(
                                    <option value={index}>{cidade.name}</option>                                            
                                ))}
                            </Cidade>
                            <Caixaerro style={ this.state.showblock ? {} : {'display' : 'none'}} >É necessário definir um estado antes de continuar a pesquisa !</Caixaerro>
                            
                            <Formulario onSubmit={this.sendSearch}>
                                <Text>Agora, digite o que procura...</Text>
                                <Buscar type="text" onChange={this.WordSearchChange} placeholder="Digite Aqui !"></Buscar>
                                <Text> ...ou selecione uma categoria de estabelecimento.</Text>         
                            </Formulario>
                            <React.Fragment>
                            {checkboxes.map(item => (
                                    <Link to={`/busca?id=${item.id}&e=${this.state.indexstate}&c=${this.state.city}`} onClick={this.handleCreate}>
                                        <Label key={item.id}>
                                            {this.state.itensid.push(item.id)}
                                            <StyledCheckbox name={item.id} >
                                                <Titlecheckbox className="textcat" checked={this.state.checkedItems.get(item.name)}>{item.name}
                                                    <Iconcat src={item.img}></Iconcat>
                                                </Titlecheckbox>
                                            </StyledCheckbox>
                                        </Label>
                                    </Link>                                    
                                ))}
                            </React.Fragment>   
                        </Col>
                    </Row>                        
                </Container>
            </section>
        )
    }
}

export default Ofertas