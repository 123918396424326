import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

const Title = styled.h1`
display: block;
margin: 0 auto;
text-align: left;
color: #FFFBF0;
width: 100%;
margin-bottom: 20px;
font-style: normal;
font-weight: 900;
font-size: 26px;
line-height: 39px;
`
const ContainerFormulario = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-content: flex-end;
align-items: flex-end;
@media (max-width: 425px){
    justify-content: center;
    align-content: center;
    align-items: center;
}
`
const FormularioContato = styled.form`
display: block;
width: 450px;
height: 400px;
margin: 40px auto;
@media(max-width: 425px){
    width: 100%;
}
`
const Textform = styled.label`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 29px;
color: #9BA0D8;
`
const Assunto = styled.input`
width: 100%;
display: block;
background-color:#FFFBF0;
border: 1px solid transparent;
border-radius: 5px;
`
const Mensagem = styled.textarea`
width: 100%;
height: 145px;
background-color: #FFFBF0;
display: block;
border: 1px solid transparent;
border-radius: 5px;
`
const Enviar = styled.button`
width:99px;
height: 50px;
background-color: #FFFBF0;
border: 1px solid transparent;
border-radius: 10px;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 33px;
float: right;
color: #5E5757;
`
class Contato extends Component {
    render() {
        const stylecontato = {
            backgroundColor: "#4A5CA7",
            marginTop: "140px"
        };
        return (
            <section className="contato" style={stylecontato}>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <ContainerFormulario>
                                <FormularioContato>
                                    <Title>Contato</Title>
                                    <Textform>Assunto
                                    <Assunto type="text"></Assunto>
                                    </Textform>
                                    <Textform>Mensagem
                                    <Mensagem></Mensagem>
                                    </Textform>
                                    <Enviar>Enviar</Enviar>
                                </FormularioContato>
                            </ContainerFormulario>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Contato