import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import axios from 'axios';

const Title = styled.h1`
display: block;
margin: 0 auto;
text-align: left;
color: #934B97;
width: 100%;
margin-bottom: 50px;
margin-left: 15px;
font-style: normal;
font-weight: 900;
font-size: 36px;
`
const Imgblogs = styled.img`
display: block;
width: 100%;
height: 300px;
margin: 0 auto;
`

const Titleblogs = styled.span`
font-style: normal;
font-weight: 900;
font-size: 19px;
line-height: 28px;
color: #4A5CA7;
`

const Text = styled.p`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 17px;
line-height: 31px;
text-align: left;
color: #575757;
white-space: nowrap; 
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis; 
`

class Blogs extends Component{
    constructor(){
        super()
        this.state = {
            blogs: []
        }
    }

    componentWillMount() {        
        axios.get('https://apicompredopequeno.opovo.com.br/api/v1/blogs', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            let {success, data} = res.data
            if (success) this.setState({ blogs: data['Compre do Pequeno'] })            
        })        
        .catch(error => {
            if (error.response) {
                console.log(error.response)   
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message);
            }
        })
    }

    getImgUrl = content => content.match('<img .* src=\\"(.*g)\\" .* \/>')[1]

    render(){
        return(
            <section className="blogs">
                <Container>                         
                    <Row>
                        <Title><a className="tit-blogs" href="http://blogs.opovo.com.br/compredopequeno/">Blog</a></Title>
                        {this.state.blogs.map(blog => (
                            <Col lg="4" md="4" sm="4"> 
                                <a href={blog.guid}>
                                    <Imgblogs src={blog.image_url}></Imgblogs>
                                    <Titleblogs>{blog.post_title}</Titleblogs>
                                    <Text>{blog.post_content}</Text>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Blogs