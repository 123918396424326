import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

const Containerpefil = styled.div`
width: 425px;
height auto;
background-color: #fff;
display: block;
padding: 10px;
margin-top: 50px;
`

const Nome = styled.span`
width: 100%;
font-size: 20px;
font-weight: 800;
margin: 20px;
display: block;
`
const Email = styled.span`
width: 100%;
font-size: 20px;
font-weight: 800;
margin: 20px;
display: block;
`
const Telefone = styled.span`
width: 100%;
font-size: 20px;
font-weight: 800;
margin: 20px;
display: block;
`


class Perfil extends Component{
    render(){
        return(
            <section className="perfil">
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <Containerpefil>
                                <Nome>Nome:</Nome>
                                <Email>Email:</Email>
                                <Telefone>Telefone:</Telefone>
                            </Containerpefil>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Perfil;