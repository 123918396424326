import Iconagua from '../../Img/agua.svg'
import Iconmaca from '../../Img/maca.svg'
import Iconcarne from '../../Img/carne.svg'
import Iconcomida from '../../Img/comida.svg'
import Iconfarmacia from '../../Img/farmacia.svg'
import Iconlimpeza from '../../Img/limpeza.svg'
import Iconlivro from '../../Img/livro.svg'
import Iconmarket from '../../Img/market.svg'
import Iconpao from '../../Img/pao.svg'
import Iconpc from '../../Img/pc.svg'
import Iconpet from '../../Img/pet.svg'
import Iconpizza from '../../Img/pizza.svg'
import Iconplant from '../../Img/plant.svg'
import Iconrestaurante from '../../Img/restaurante.svg'
import Iconservicos from '../../Img/servicos.svg'
import Iconsorvete from '../../Img/sorvete.svg'
import Iconmarketplace from '../../Img/marketplace.svg'
import Iconall from '../../Img/all.svg'
const checkboxes = [
    {
      name: 'Água',
      id: '1',
      img: Iconagua,
      label: 'Água',
    },
    {
      name: 'Alimentação',
      id: '2',
      img: Iconmaca,
      label: 'Alimentação',
    },
    {
      name: 'Carnes e frutos do mar',
      id: '9',
      img: Iconcarne,
      label: 'Carne e frutos do mar',
    },
    {
      name: 'Farmácia',
      id: '10',
      img: Iconfarmacia,
      label: 'Farmácia',
    },
    {
      name: 'Informática',
      id: '13',
      img: Iconpc,
      label: 'Informática',
    },
    {
      name: 'Lanchonetes',
      id: '4',
      img: Iconsorvete,
      label: 'Lanchonetes',
    },
    {
      name: 'Limpeza',
      id: '14',
      img: Iconlimpeza,
      label: 'Limpeza',
    },
    {
      name: 'Marmitarias',
      id: '5',
      img: Iconcomida,
      label: 'Marmitarias',
    },
    {
      name: 'Padarias',
      id: '7',
      img: Iconpao,
      label: 'Padarias',
    },
    {
      name: 'Papelarias e Livrarias',
      id: '15',
      img: Iconlivro,
      label: 'Papelarias e Livrarias',
    },
    {
      name: 'Petshop',
      id: '12',
      img: Iconpet,
      label: 'Petshop',
    },
    {
      name: 'Pizzarias',
      id: '8',
      img: Iconpizza,
      label: 'Pizzarias',
    },
    {
      name: 'Restaurantes',
      id: '3',
      img: Iconrestaurante,
      label: 'Restaurantes',
    },
    {
      name: 'Saúde',
      id: '11',
      img: Iconplant,
      label: 'Saúde',
    },
    {
      name: 'Serviços',
      id: '16',
      img: Iconservicos,
      label: 'Serviços',
    },
    {
      name: 'Supermercados',
      id: '6',
      img: Iconmarket,
      label: 'Supermercados',
    },
    {
      name: 'Outros',
      id: '17',
      img: Iconmarketplace,
      label: 'Outros',
    },
    {
      name: 'Todos',
      id: '18',
      img: Iconall,
      label: 'Todos',
    },
  ];
  
  export default checkboxes;