import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Menu from '../Menu.jsx';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const Title = styled.h1`
display: block;
margin: 0 auto;
text-align: left;
color: #575757;
width: 100%;
margin-top: 30px;
margin-bottom: 30px;
font-style: normal;
font-weight: 900;
font-size: 28px;
line-height:42px;
`
const Text = styled.p`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 40px;
color:#575757;
text-align: left;
`
const Cadastrese = styled.button`
width: 150px;
height: 70px;
background-color: #FFFBF0;
border: 1px solid #ACB0DA;
box-sizing: border-box;
box-shadow: 0px 3px 2px #ACB0DA;
float: right;
margin-right: 15px;
border-radius: 10px;
position: relative;
z-index: 9;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 33px;
margin-top: 20px;
&:hover{
    background-color: #4A5CA7;
}
@media(max-width: 320px){
    margin-top: 0px;
}
`

class Termodeuso extends Component {
    render(){
        return(
            <div>
                <Menu></Menu>
                <section className="termodeuso">
                    <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Title>Termos de uso</Title>
                                <Text>O Projeto Compre do Pequeno é uma ferramenta restrita à disponibilização do espaço que visa possibilitar um ambiente virtual de negócios saudáveis, objetivando aproximar clientes e fornecedores de serviços. O Compre do Pequeno, portanto, não tem responsabilidade sobre os produtos e serviços oferecidos, assim como, não guarda posse e não intervém na definição de preço dos produtos. Sendo assim, toda compra e venda de produtos ou contratação de serviços se dá entre os usuários, sem envolvimento do Compre do Pequeno. A responsabilidade sobre os produtos e serviços oferecidos e sobre a realização e sucesso de toda transação financeira caberá sempre e exclusivamente aos usuários.</Text>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

export default Termodeuso;