import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import axios from 'axios';
import Popup from 'reactjs-popup';
import CurrencyInput from 'react-currency-masked-input';
import Menu from './Menu.jsx';
import Iconface from '../Img/facebook.png';


const Formulario = styled.form`
display: block;
height: auto;
border: 1px solid transparent;
border-radius: 10px;
margin: 60px auto;
padding-bottom: 100px;
@media(max-width: 425px){
    width: 100%;
}
`
const Boxcampo = styled.label`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
margin: 0 15px;
color: #575757;
position: relative;
`
const Titlebox = styled.h1`
display: block;
font-family: Rounded Mplus 1c;
font-style: normal;
font-weight: 900;
font-size: 28px;
line-height: 42px;
color: #575757;
`
const Campo = styled.input`
display: block;
background-color:#FFFBF0;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;
width: 100%;
padding-left: 10px;
`
/*================ User =================*/
const Boxuser = styled.div`
width: 100%;
display: block;
`
const Inputmedio = styled.div`
width: 50%;
display block;
float: left;
@media(max-width: 425px){
    width: 100%;
}
`

const Inputsmall = styled.div`
width: 33.33%;
float: left;
@media(max-width: 425px){
    width: 100%;
}
`
const Buttonnext = styled.span`
width: 140px;
height: 46px;
background-color: #FFFBF0;
border: 1px solid #ACB0DA;
border-radius: 10px;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
color: #5E5757;
float: right;
margin-top: 15px;
cursor: pointer;
text-align: center;
padding-top: 10px;
margin-right: 15px;
&:hover{
    background-color: #c4c4c4;
    color: #fff;
}

`
/*========================================*/
/*============== Box Store ===============*/
const Boxstore = styled.div`
width: 100%;
display: block;
`
const Inputlow = styled.div`
width: 25%;
display: block;
float: left;
@media(max-width: 425px){
    width: 100%;
}
`
const Inputhigh = styled.div`
width: 75%;
display: block;
float: left;
@media(max-width: 425px){
    width: 100%;
}
`
/*========================================*/
const Cadastrar = styled.button`
width: 140px;
height: 46px;
background-color: #FFFBF0;
border: 1px solid #ACB0DA;
border-radius: 10px;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 26px;
color: #5E5757;
float: right;
margin-top: 15px;
margin-right: 15px;
&:hover{
    background-color: #c4c4c4;
    color: #fff;
}
`
const Estado = styled.select`
width: 100%;
display: block;
background-color:#FFFBF0;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 5px;;
width: 100%;
padding-left: 10px;
color: #9BA0D8;
`

class CadastroComerciante extends Component {
    constructor() {
        super()
        this.state = {
            headersRequest: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            showblock: false,
            erros: [],
            messagestatus: [],
            categories: [],
            stateall: [],
            indexstate: null,
            indexcity: null,
            indexbairro: null,
            u_name: '',
            u_email: '',
            u_password: '',
            u_cpf: '',
            u_cnpj: '',
            u_phone: '',
            s_name: '',
            s_zip_code: '',
            s_state: '',
            s_city: '',
            s_neighborhood: '',
            s_street: '',
            s_number: '',
            s_about: '',
            s_withdrawal: '',
            s_delivery_value: '',
            s_category_id: '',
            s_seg: null,
            s_ter: null,
            s_qua: null,
            s_qui: null,
            s_sex: null,
            s_sab: null,
            s_dom: null,
            s_whap: '',
            s_face: '',
            s_insta: '',
        }
    }
    NameChange = (e) => this.setState({ u_name: e.target.value });
    EmailChange = (e) => this.setState({ u_email: e.target.value });
    PasswordChange = (e) => this.setState({ u_password: e.target.value });
    CpfChange = (e) => this.setState({ u_cpf: e.target.value });
    CnpjChange = (e) => this.setState({ u_cnpj: e.target.value });
    PhoneChange = (e) => this.setState({ u_phone: e.target.value });
    NameStoreChange = (e) => this.setState({ s_name: e.target.value });
    ZipcodeStoreChange = (e) => this.setState({ s_zip_code: e.target.value });
    
    StateStoreChange = (e) => {    
        this.setState({
            s_state: this.state.stateall[e.target.value].uf,
            indexstate: e.target.value,
        })        
    }

    CityStoreChange = (e) => {
        this.setState({ 
            s_city: this.state.stateall[this.state.indexstate].cities[e.target.value].code,
            indexcity: e.target.value 
        })
    }
    NeighborhoodStoreChange = (e) => {
        this.setState({
             s_neighborhood: e.target.value
        });
    } 
    StreetcodeStoreChange = (e) => this.setState({ s_street: e.target.value });
    NumbercodeStoreChange = (e) => this.setState({ s_number: e.target.value });
    AboutStoreChange = (e) => this.setState({ s_about: e.target.value });
    WithdrawalStoreChange = (e) => this.setState({ s_withdrawal: e.target.value });
    DeliveryvalueStoreChange = (e) => this.setState({ s_delivery_value: e.target.value * 10 });
    CategoriesvalueStoreChange = (e) => this.setState({ s_category_id: e.target.value });
    SegvalueStoreChange = (e) => this.setState({ s_seg: e.target.value });
    TervalueStoreChange = (e) => this.setState({ s_ter: e.target.value });
    QuavalueStoreChange = (e) => this.setState({ s_qua: e.target.value });
    QuivalueStoreChange = (e) => this.setState({ s_qui: e.target.value });
    SexvalueStoreChange = (e) => this.setState({ s_sex: e.target.value });
    SabvalueStoreChange = (e) => this.setState({ s_sab: e.target.value });
    DomvalueStoreChange = (e) => this.setState({ s_dom: e.target.value });
    FacebookChange = (e) => this.setState({ s_face: e.target.value });
    InstagramChange = (e) => this.setState({ s_insta: e.target.value });
    WhatsappChange = (e) => this.setState({ s_whap: e.target.value });

    sendRegister = () => {
        const user = this.mountObjectUser()
        axios.post('https://apicompredopequeno.opovo.com.br/api/v1/register', user, {
            headers: this.state.headersRequest,
        })
        .then(res => {
            this.setState({erros: []})
            window.scroll(0, 0);
            document.getElementById('formRegister').reset();
            this.setState({messagestatus: [res.data.message]});
        }).catch(error => {
            if (error.response) {
                let errs = error.response.data.errors
                window.scroll(0, 0)
                this.setState({erros: []})
                for(let e in errs) 
                    for(let error of errs[e]) this.setState({erros: [...this.state.erros, error] })

                //console.log(error.response)   
            } else if (error.request) {
                //console.log(error.request);
            } else {
                /* console.log('Error', error);
                console.log('Error', error.response);
                console.log('Error', error.message); */
            }
        })
    }

    animationPro = (e) => {
        this.setState({ showblock: true });
        
    }

    mountObjectUser() {
        const user = {
            "name": this.state.u_name,
            "email": this.state.u_email,
            "password": this.state.u_password,
            "cpf": this.state.u_cpf,
            "phone": this.state.u_phone,
            "store": {
              "name": this.state.s_name,
              "zip_code": this.state.s_zip_code,
              "state": this.state.s_state,
              "city": this.state.s_city,
              "neighborhood": this.state.s_neighborhood,
              "street": this.state.s_street,
              "number": this.state.s_number,
              "seg": this.state.s_seg || '00:00-00:00',
              "ter": this.state.s_ter || '00:00-00:00',
              "qua": this.state.s_qua || '00:00-00:00',
              "qui": this.state.s_qui || '00:00-00:00',
              "sex": this.state.s_sex || '00:00-00:00',
              "sab": this.state.s_sab || '00:00-00:00',
              "dom": this.state.s_dom || '00:00-00:00',
              "about": this.state.s_about,
              "withdrawal": this.state.s_withdrawal || 'n',
              "delivery_value": this.state.s_delivery_value || 0.0,
              "category_id": this.state.s_category_id,
              "whatsapp": this.state.s_whap || '',
              "instagram": this.state.s_insta || '',
              "facebook": this.state.s_face || ''
            }
          }
          
        if (this.state.u_cnpj) user.cnpj = this.state.u_cnpj
        
        return user;
    }

    componentDidMount() {
        axios.get(`https://apicompredopequeno.opovo.com.br/api/v1/categories`, {
            headers: this.state.headersRequest,
        })
        .then(res => this.setState({ categories: res.data }))

        axios.get('https://apicompredopequeno.opovo.com.br/api/v1/information', {
            headers: this.state.headersRequest,
        })
        .then(res => this.setState({ stateall: res.data }))
    }

    render() {
        const maskcampo = {
            display: "block",
            width: "100%",
            background: "#fffbf0",
            border: "1px solid #C4C4C4",
            borderRadius: "5px",
            paddingLeft: "10px"
        };
        const erromark = {
            color: "#d93025"
        };
        const verifymark = {
            color: "#4db6ac"
        };
        return (
            <section className="cadastrocomerciante">
                <Menu></Menu>
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">                            
                            <Formulario id="formRegister">
                            <Boxcampo>(*) Campos obrigatórios</Boxcampo><br />
                            <div className="container-erros">
                                    <ul>
                                        {this.state.erros.map((todoserros, key) => (
                                            <li style={erromark}>{todoserros}</li>
                                        ))}
                                         {this.state.messagestatus.map((messager, key) => (
                                            <li style={verifymark}>{messager}</li>
                                        ))}
                                    </ul>
                                 </div>
                                 <Boxuser style={ this.state.showblock ? {'display' : 'none'} : {}}>
                            <Titlebox>1. Cadastramento</Titlebox>
                            <Inputmedio>
                                <Boxcampo>Nome Completo*:
                                    <Campo type="text" name="nome" onChange={this.NameChange}></Campo>
                                </Boxcampo>
                                </Inputmedio>
                                <Inputmedio>
                                <Boxcampo>CPF*:
                                    <InputMask mask="999.999.999-99" name="cpf" style={maskcampo} onChange={this.CpfChange}/>
                                </Boxcampo>  
                                </Inputmedio>
                                <Inputsmall>
                                <Boxcampo>Senha*:
                                    <Campo type="password" name="senha"  onChange={this.PasswordChange}></Campo>
                                </Boxcampo>
                                </Inputsmall>
                                <Inputsmall>
                                <Boxcampo>Email*:
                                    <Campo type="email" name="email" onChange={this.EmailChange}></Campo>
                                </Boxcampo>    
                                </Inputsmall>
                                <Inputsmall>             
                                <Boxcampo>Telefone*:
                                    <InputMask mask="(99)99999-9999" name="telefone" style={maskcampo} onChange={this.PhoneChange}/>
                                </Boxcampo>
                                </Inputsmall>
                                <Buttonnext onClick={this.animationPro}>Prosseguir</Buttonnext>
                                </Boxuser>
                                <Boxstore style={ this.state.showblock ? {} : {'display' : 'none'}}>
                                <Titlebox>2. Perfil do estabelecimento</Titlebox>
                                
                                <Boxcampo>Nome da Empresa*:
                                    <Campo type="text" name="nomeloja" onChange={this.NameStoreChange}></Campo>
                                </Boxcampo>
                               
                                <Boxcampo>CNPJ:
                                    <InputMask mask="99.999.999/9999-99" name="cnpj" style={maskcampo} onChange={this.CnpjChange}/>
                                </Boxcampo>
                               
                                <Boxcampo>Categoria*:
                                    <Estado select="categoires" onChange={this.CategoriesvalueStoreChange}>
                                        <option>Selecione uma Categoria</option>
                                        {this.state.categories.map((todo) => (
                                            <option value={todo.id}>{todo.name}</option>
                                        ))}
                                    </Estado>
                                </Boxcampo>
                                <Boxcampo>cep*:
                                    <InputMask mask="99999-999" style={maskcampo}  name="cep" onChange={this.ZipcodeStoreChange}></InputMask>
                                </Boxcampo>
                               
                                <Boxcampo>Estado*:
                                    <Estado onChange={this.StateStoreChange} name="estado">                                        
                                        <option>Selecione um Estado</option>
                                        {this.state.stateall.map((state, index) => (
                                            <option value={index}>{state.uf}</option>
                                        ))}
                                    </Estado>
                                </Boxcampo>
                                
                                <Boxcampo>Cidade*:
                                    <Estado select="cidade" onChange={this.CityStoreChange}>
                                        <option>Selecione uma Cidade</option>
                                        {((this.state.indexstate ? this.state.stateall[this.state.indexstate].cities : [])).map((cidade, index) =>(
                                            <option value={index}>{cidade.name}</option>
                                        ))}
                                    </Estado>
                                </Boxcampo>
                               
                                <Boxcampo>Bairro*:
                                    <Estado select="bairro" onChange={this.NeighborhoodStoreChange}>
                                        <option>Selecione um Bairro</option>
                                        {((this.state.indexcity && this.state.stateall[this.state.indexstate].cities[this.state.indexcity].neighborhood.length > 0 ? this.state.stateall[this.state.indexstate].cities[this.state.indexcity].neighborhood : [])).map((bairro) =>(
                                            <option value={bairro.code}>{bairro.name}</option>
                                        ))} 
                                    </Estado>
                                </Boxcampo>
                               
                                <Boxcampo>Número da Empresa*:
                                    <Campo type="text" name="numero" onChange={this.NumbercodeStoreChange}></Campo>
                                </Boxcampo>
                               
                                <Boxcampo>Descrição da Empresa*:
                                    <Campo type="text" name="sobre" onChange={this.AboutStoreChange}></Campo>
                                </Boxcampo>
                                <Boxcampo>Rua*:
                                    <Campo type="text" name="rua" onChange={this.StreetcodeStoreChange}></Campo>
                                </Boxcampo>
                                <Boxcampo>Retirada*:<br></br>
                                    Sim:<Campo type="radio" name="retirada" value="y" onChange={this.WithdrawalStoreChange}></Campo>
                                    Não:<Campo type="radio" name="retirada" value="n" checked onChange={this.WithdrawalStoreChange}></Campo>
                                </Boxcampo>
                                <Boxcampo>Valor do Delivery:
                                    <CurrencyInput name="entrega" onChange={this.DeliveryvalueStoreChange } style={maskcampo} placeholder="5.60" />
                                </Boxcampo>
                                <Boxcampo><br/>Horário de funcionamento: (caso não funcione deixe o campo vazio)</Boxcampo><br/>

                                <Boxcampo>Seg*:
                                    <InputMask mask="99:99-99:99" name="seg" style={maskcampo} onChange={this.SegvalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Ter*:
                                    <InputMask mask="99:99-99:99" name="ter"  style={maskcampo} onChange={this.TervalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Quar*:
                                    <InputMask mask="99:99-99:99" name="qua" style={maskcampo} onChange={this.QuavalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Quin*:
                                    <InputMask mask="99:99-99:99" name="qui" style={maskcampo} onChange={this.QuivalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Sex*:
                                    <InputMask mask="99:99-99:99" name="sex" style={maskcampo} onChange={this.SexvalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Sab*:
                                    <InputMask mask="99:99-99:99" name="sab" style={maskcampo} onChange={this.SabvalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                <Boxcampo>Dom*:
                                    <InputMask mask="99:99-99:99" name="dom" style={maskcampo} onChange={this.DomvalueStoreChange} placeholder="08:30-19:00"/>
                                </Boxcampo>
                                
                                <br />
                                <Boxcampo>Redes Sociais</Boxcampo>                                
                                <Boxcampo className="facebook">Facebook:
                                    <Campo name="facebook" className="facebookinput" type="url" placeholder="https://www.facebook.com/compredopequeno" onChange={this.FacebookChange}/>
                                </Boxcampo>                                
                                <Boxcampo className="instagram">Instagram:
                                    <Campo name="instagram" className="instagraminput" placeholder="@compredopequeno" onChange={this.InstagramChange}/>
                                </Boxcampo>                                
                                <Boxcampo className="wpp">Whatsapp:
                                    <InputMask className="wppinput" mask="(99)99999-9999" style={maskcampo} name="whatsapp" onChange={this.WhatsappChange}/>
                                </Boxcampo>
                                <br />
                                
                                <Cadastrar type="button" onClick={this.sendRegister}>Cadastrar</Cadastrar>
                                </Boxstore>
                            </Formulario>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default CadastroComerciante