import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Termodeuso from './Termodeuso.jsx';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const Title = styled.h1`
display: block;
text-align: left;
color: #fff;
margin: 15px;
font-style: normal;
font-weight: 900;
font-size: 36px;
line-height: 53px; 
`
const Text = styled.p`
display: block;
font-family: Martel Sans;
font-style: normal;
font-weight: normal;
font-size: 21px;
line-height: 38px;
text-align: left;
color: #fff;
margin: 15px; 
`
const Card = styled.div`
width: 100%;
height: 350px;
background-color: #934B97;
border: 1px solid transparent;
border-radius: 10px;
margin-bottom: 90px;
margin-top: 90px;
@media(max-width: 425px){
    height: 680px;
}
`
const Cadastrese = styled.button`
width: 150px;
height: 70px;
background-color: #FFFBF0;
float: right;
margin-right: 15px;
border: 1px solid transparent;
border-radius: 5px;
color: #545454;
font-family: Martel Sans;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 33px;
margin-top: 20px;
@media(max-width: 320px){
    margin-top: 0px;
}
`


class Cadastro extends Component{
    render(){
        return(
            <section className="Cadastro">
                <Container>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <Card>
                                <Title>É gratuito!</Title>
                                <Text>Você que é um pequeno ou médio empreendedor pode se cadastrar para fazer parte do banco de negócios locais que todo consumidor consciente procura. Com um cadastro gratuito, além da visibilidade, você terá acesso a conteúdos práticos e profissionalizantes para fortalecer seu negócio. </Text>
                                <Cadastrese><Link to='/cadastrocomerciante'>Cadastrar-se</Link></Cadastrese>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Cadastro