import React from 'react';
import Home from './Components/Home.jsx'
import Busca from './Components/Busca.jsx'
import CadastroComerciante from './Components/CadastroComerciante.jsx'
import Login from './Components/Login.jsx'
import Termodeuso from './Components/Home/Termodeuso.jsx'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const App = <Router>
                <Switch>
                  <Route path="/" exact={true} component={Home}></Route>
                  <Route path="/busca" component={Busca}></Route>
                  <Route path="/cadastrocomerciante" component={CadastroComerciante}></Route>
                  <Route path="/login" component={Login}></Route>
                  <Route path="/termodeuso" component={Termodeuso}></Route>
                </Switch>
              </Router>

export default App;

